<template>
  <HelpComponent :helpData="helpData" />
</template>

<script>
import HelpComponent from './helpComponent.vue'
export default {
    components: {
        HelpComponent
    },
    data() {
        return {
            helpData:[
                {   id:'1',
                    isActive: false,
                    title: 'Urna quis nulla vitae, condimentum varius in adipiscing blandit. Vitae sodales id feugiat parturient venenatis.',
                    body: [
                        'Ut maecenas tortor sapien donec ut egestas. Ac aliquam vitae justo id a et at nibh in. Sed at maecenas egestas dui. Nunc mauris rhoncus, vel senectus curabitur amet, varius proin id. Tristique venenatis morbi ut adipiscing massa, et in. Vitae vel consectetur dui tempus.'
                    ]
                },
                {   id:'2',
                    isActive: false,
                    title: 'Urna quis nulla vitae, condimentum varius in adipiscing blandit. Vitae sodales id feugiat parturient venenatis.',
                    body: [
                        'Ut maecenas tortor sapien donec ut egestas. Ac aliquam vitae justo id a et at nibh in. Sed at maecenas egestas dui. Nunc mauris rhoncus, vel senectus curabitur amet, varius proin id. Tristique venenatis morbi ut adipiscing massa, et in. Vitae vel consectetur dui tempus.'
                    ]
                },
                {   id:'3',
                    isActive: false,
                    title: 'Urna quis nulla vitae, condimentum varius in adipiscing blandit. Vitae sodales id feugiat parturient venenatis.',
                    body: [
                        'Ut maecenas tortor sapien donec ut egestas. Ac aliquam vitae justo id a et at nibh in. Sed at maecenas egestas dui. Nunc mauris rhoncus, vel senectus curabitur amet, varius proin id. Tristique venenatis morbi ut adipiscing massa, et in. Vitae vel consectetur dui tempus.'
                    ]
                }
            ]
        }
    }
}
</script>

<style>

</style>