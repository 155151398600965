<template>
    <div class="help_collapse_section">
        <div class="help_collapse_icon">
            <button class="__help_btn" @click="helpItemData.isActive = !helpItemData.isActive">
                <img v-if="!helpItemData.isActive" src="../../assets/help_plus.svg" alt="">
                <img v-else width="15px" height="15px" src="../../assets//minus-line-svgrepo-com (1).svg" alt="">
            </button>
        </div>
        <div class="help_collapse_content">
            <div class="help_collapse_header">
                <h2>
                    {{
                        
                        helpItemData.title
                    }}
                </h2>
            </div>
            <div class="help_collapse_body" v-if="helpItemData.isActive">
                <p v-for="(item,key) in helpItemData.body" :key="key">
                    {{
                        item
                    }}
                </p>
            </div>
            <div class="__help_border_bottom">

            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        helpItemData: {
            required: true,
            type: Object
        }
    }
}
</script>

<style>

</style>