<template>
  <div class="__help_component_wrapper">
        <div class="__help_collapse_sections">
            <helpItem v-for="item in helpData" :key="item.key" :helpItemData="item" />
        </div>
  </div>
</template>

<script>
import helpItem from './helpItem.vue'
export default {
    components: {
        helpItem
    },
    props: {
        helpData: {
            required: true,
            type: Array
        }
    }
}
</script>

<style>

</style>